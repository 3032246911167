'use strict';

var getBusinessObject = require('@/bpmn-modules/bpmn-js/lib/util/ModelUtil').getBusinessObject,
    is = require('@/bpmn-modules/bpmn-js/lib/util/ModelUtil').is;

var multiInstanceLoopCharacteristics = require('./implementation/MultiInstanceLoopCharacteristics');

var jobRetryTimeCycle = require('./implementation/JobRetryTimeCycle'),
    asyncContinuation = require('./implementation/AsyncContinuation');


function getLoopCharacteristics (element) {
    var bo = getBusinessObject(element);
    return bo.loopCharacteristics;
}


function ensureMultiInstanceSupported (element) {
    var loopCharacteristics = getLoopCharacteristics(element);
    return !!loopCharacteristics && is(loopCharacteristics, 'camunda:Collectable');
}

module.exports = function (group, element, bpmnFactory, translate) {

    if (!ensureMultiInstanceSupported(element)) {
        return;
    }

    // multi instance properties
    group.entries = group.entries.concat(multiInstanceLoopCharacteristics(element, bpmnFactory, translate));

    // async continuation ///////////////////////////////////////////////////////
    group.entries = group.entries.concat(asyncContinuation(element, bpmnFactory, {
        getBusinessObject: getLoopCharacteristics,
        idPrefix: 'multiInstance-',
        labelPrefix: translate('Multi Instance ')
    }, translate));


    // retry time cycle //////////////////////////////////////////////////////////
    group.entries = group.entries.concat(jobRetryTimeCycle(element, bpmnFactory, {
        getBusinessObject: getLoopCharacteristics,
        idPrefix: 'multiInstance-',
        labelPrefix: translate('Multi Instance ')
    }, translate));
};
