/**
 * A simple translation stub to be used for multi-language support
 * in diagrams. Can be easily replaced with a more sophisticated
 * solution.
 *
 * @example
 *
 * // use it inside any diagram component by injecting `translate`.
 *
 * function MyService(translate) {
 *   alert(translate('HELLO {you}', { you: 'You!' }));
 * }
 *
 * @param {string} template to interpolate
 * @param {Object} [replacements] a map with substitutes
 *
 * @return {string} the translated string
 */
export default function translate (template, replacements) {
    const translations = {
        'General': '属性',
        'Id': '标识符',
        'Name': '名称',
        'Version Tag': '版本',
        'Executable': '是否可执行',
        'Details': '详情',
        'Candidate Starter Configuration': '任务发起人配置',
        'Candidate Starter Groups': '发起用户组',
        'Candidate Starter Users': '发起用户',
        'Due Date': '截止时间',
        'Follow Up Date': '跟进时间',
        'Priority': '优先级',
        'Script Format': '格式化脚本',
        'Script Type': '脚本类型',
        'Inline Script': '代码',
        'External Resource': '外部资源',
        'Forms': '表单',
        'Form Key': '点击输入框选择表单',
        "Candidate Users": '候选人',
        "Candidate Groups": '候选人组',
        "Assignee": '任务执行人',
        "Condition Type": '条件类型',
        'Expression': '表达式',
        'Script': '脚本',
        "Listeners": "监听器",
        'Execution Listener': '执行监听器',
        'Task Listener': '任务监听器',
        "Event Type": '事件类型',
        'Listener Type': '监听器类型',
        'Java Class': 'Java类',
        'Task': '任务',
        'User Task': '用户任务',
        'Listener Id': '事件标识符',
        'create': '创建',
        'assignment': '分派',
        'complete': '完成',
        'delete': '删除',
        'update': '更新',
        'timeout': '超时',
        'Timer Definition Type': '时间器类型',
        'Cycle': '周期',
        'Duration': '时间段',
        'Date': '日期',
        'Timer Definition': '定时器定义'
    }


    replacements = replacements || {};
    // Translate
    template = translations[template] || template;

    return template.replace(/{([^}]+)}/g, function (_, key) {
        return replacements[key] || '{' + key + '}';
    });
}