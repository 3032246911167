var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "work-order-flow-design-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                  1
                ),
                _c(
                  "a-breadcrumb-item",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/dashboard/professional-relation" } },
                      [_vm._v("工单设置")]
                    )
                  ],
                  1
                ),
                _c(
                  "a-breadcrumb-item",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/dashboard/work-order-flow" } },
                      [_vm._v("流程设计")]
                    )
                  ],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v(_vm._s(_vm.title))])
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "page-body page-body-margin" }, [
        _c("div", { staticClass: "content" }, [
          _c("div", {
            ref: "canvas",
            staticClass: "content-left",
            attrs: { id: "canvas" }
          }),
          _c("div", {
            ref: "js-properties-panel",
            staticClass: "content-right",
            attrs: { id: "js-properties-panel" }
          })
        ]),
        _c(
          "div",
          {
            staticClass: "footer",
            staticStyle: { display: "flex", "align-items": "center" }
          },
          [
            _c(
              "jtl-button",
              {
                staticClass: "margin-right-18",
                attrs: { type: "primary" },
                on: { click: _vm.saveFlow }
              },
              [_vm._v("保存")]
            ),
            _c(
              "a",
              {
                ref: "saveDiagram",
                staticClass: "bottom-btn",
                attrs: { href: "javascript:", title: "保存为bpmn" }
              },
              [_vm._v("保存bpmn")]
            ),
            _c(
              "a",
              {
                ref: "saveSvg",
                staticClass: "bottom-btn",
                attrs: { href: "javascript:", title: "保存为svg" }
              },
              [_vm._v("保存svg")]
            ),
            _c(
              "a",
              {
                staticClass: "bottom-btn",
                attrs: { href: "javascript:" },
                on: {
                  click: function($event) {
                    return _vm.$refs.refFile.click()
                  }
                }
              },
              [_vm._v("加载本地BPMN文件")]
            ),
            _c("input", {
              ref: "refFile",
              staticStyle: { display: "none" },
              attrs: { id: "files", type: "file" },
              on: { change: _vm.loadXML }
            }),
            _c(
              "jtl-button",
              {
                staticClass: "margin-right-18",
                attrs: { type: "cancel" },
                on: { click: _vm.cancel }
              },
              [_vm._v("取消")]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }