






























import { Component } from 'vue-property-decorator';
import WorkOrderFlowService from '@/service/work-order-flow';
import { BaseComponent } from '@/mixins/base-component';
import BpmnModeler from '@/bpmn-modules/bpmn-js/lib/Modeler';
// 左边工具栏以及编辑节点的样式
import '@/bpmn-modules/bpmn-js/dist/assets/diagram-js.css';
import '@/bpmn-modules/bpmn-js/dist/assets/bpmn-font/css/bpmn.css';
import '@/bpmn-modules/bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css';
import '@/bpmn-modules/bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';
import propertiesProviderModule from '@/bpmn-modules/bpmn-js-properties-panel/lib/provider/camunda';
/* 右侧属性栏样式与属性栏 */
import '@/bpmn-modules/bpmn-js-properties-panel/dist/assets/bpmn-js-properties-panel.css';
import propertiesPanelModule from '@/bpmn-modules/bpmn-js-properties-panel';
const camundaModdleDescriptor = require('@/bpmn-modules/camunda-bpmn-moddle/resources/camunda');

@Component
export default class WorkOrderFlowListComponent extends BaseComponent {
    flowId: string = undefined;

    bpmnModeler: any = undefined;

    flowData: any = undefined;

    downloadLink: any = undefined;

    downloadSvgLink: any = undefined;

    title: string = '';

    created() {
        this.flowId = this.$route.params.id;

        WorkOrderFlowService.query().then(res => {
            this.title = _.filter(res.items, item => { return item.processKey === this.flowId; })[0].processName;
        });

        this.startFullScreenLoading();

        WorkOrderFlowService.getFlowDetail(this.flowId).then(res => {
            this.flowData = res;

            this.downloadLink = this.$refs.saveDiagram;

            this.downloadSvgLink = this.$refs.saveSvg;

            this.init();
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }
    init() {
        let canvas = this.$refs.canvas;

        this.bpmnModeler = new BpmnModeler({
            container: canvas,
            propertiesPanel: {
                parent: '#js-properties-panel'
            },
            additionalModules: [
                propertiesPanelModule,
                propertiesProviderModule
            ],
            moddleExtensions: {
                camunda: camundaModdleDescriptor
            }
        } as any);

        const _this = this;

        this.bpmnModeler.importXML(this.flowData).then(res => {
            // 让图能自适应屏幕
            var canvas = _this.bpmnModeler.get('canvas');

            canvas.zoom('fit-viewport');

            _this.success();
        }).catch(err => {
            console.log(err.message, err.warnings);
        });
    }

    loadXML() {
        const that = this;

        const file = (this.$refs.refFile as any).files[0];

        var reader = new FileReader();

        reader.readAsText(file);

        reader.onload = function() {
            that.flowData = this.result;

            that.bpmnModeler.importXML(that.flowData).then(res => {
                var canvas = that.bpmnModeler.get('canvas');

                canvas.zoom('fit-viewport');

                that.success();
            });
        };
    }

    // 下载为SVG格式,done是个函数，调用的时候传入的
    saveSVG() {
        this.bpmnModeler.saveSVG().then(res => {
            this.setEncoded(this.downloadSvgLink, this.flowId + '.svg', res.err ? null : res.svg);
        });
    }
    // 下载为bpmn格式,done是个函数，调用的时候传入的
    saveDiagram() {
        // 把传入的done再传给bpmn原型的saveXML函数调用
        this.bpmnModeler.saveXML({ format: true }).then(res => {
            this.setEncoded(this.downloadLink, this.flowId + '.bpmn', res.err ? null : res.xml);
        });
    }

    // 当图发生改变的时候会调用这个函数，这个data就是图的xml
    setEncoded(link, name, data) {
        // 把xml转换为URI，下载要用到的
        const encodedData = encodeURIComponent(data);
        // 下载图的具体操作,改变a的属性，className令a标签可点击，href令能下载，download是下载的文件的名字
        if (data) {
            link.className = 'active bottom-btn';

            link.href = 'data:application/bpmn20-xml;charset=UTF-8,' + encodedData;

            link.download = name;
        }
    }

    saveFlow() {
        let formData = new FormData();

        this.bpmnModeler.saveXML({ format: true }).then(res => {
            this.flowData = res.xml;

            formData.append('file', new Blob([this.flowData], { type: 'text/xml' }), this.flowId + '.bpmn20.xml');

            WorkOrderFlowService.saveXML(formData).then(res => {
                this.showMessageSuccess('保存成功！');
            });
        });
    }

    success() {
        // 给图绑定事件，当图有发生改变就会触发这个事件
        this.bpmnModeler.on('commandStack.changed', () => {
            this.saveSVG();

            this.saveDiagram();
        });

        this.bpmnModeler.on('import.parse.complete', (event) => {
            if (!event.error) {
                event.definitions.rootElements[0].id = this.flowId;

                event.definitions.rootElements[0].name = this.title;
            }
        });

        this.saveSVG();

        this.saveDiagram();

        this.addModelerListener();
    }

    addModelerListener() {
        const bpmnjs = this.bpmnModeler;

        const moddle = bpmnjs.get('moddle');

        const elementRegistry = bpmnjs.get('elementRegistry');

        const events = ['shape.added'];
        events.forEach((event) => {
            bpmnjs.on(event, e => {
                let shape = e.element ? elementRegistry.get(e.element.id) : e.shape;

                if (shape.type === 'bpmn:UserTask') {
                    // 添加创建监听器
                    const newExtensionElements = moddle.create('bpmn:ExtensionElements', { values: [] });

                    shape.businessObject['extensionElements'] = newExtensionElements;

                    const newListener = moddle.create('camunda:TaskListener', { delegateExpression: '$' + '{taskAssigneeApplyUserListener}', event: 'create' });

                    shape.businessObject['extensionElements'].values.push(newListener);
                }
                this.$nextTick(() => {
                    // 如果来自网关，为连线添加Expression属性
                    shape.incoming.map(item => {
                        const incomingId = item.id;

                        let incoming = elementRegistry.get(incomingId);

                        this.addTaskListener(item);

                        if (incoming.source && incoming.source.type === 'bpmn:ExclusiveGateway') {
                            const sourceId = this.generateRandomStr(6);

                            const targetId = this.generateRandomStr(6);

                            const newCondition = moddle.create('bpmn:FormalExpression', { body: '${' + sourceId + '_to_' + targetId + '}' });

                            incoming.businessObject.conditionExpression = newCondition;
                        }
                    });
                });
            });
        });
    }

    generateRandomStr(length) {
        const len = length || 48;

        const t = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

        const a = t.length;

        let n = '';

        for (let i = 0; i < len; i++) {
            n += t.charAt(Math.floor(Math.random() * a));
        }

        return n;
    }

    addTaskListener(item) {
        const moddle = this.bpmnModeler.get('moddle');

        const newExtensionElementsItem = moddle.create('bpmn:ExtensionElements', { values: [] });

        item.businessObject['extensionElements'] = newExtensionElementsItem;

        const newListenerItem = moddle.create('camunda:ExecutionListener', { delegateExpression: '$' + '{commonExecutionListener}', event: 'take' });

        item.businessObject['extensionElements'].values.push(newListenerItem);
    }

    shortenValue(value) {
        const valueShort = value.indexOf('_') > -1 ? value.substring(value.lastIndexOf('_') + 1, value.length) : value;

        return valueShort;
    }

    cancel() {
        this.$router.push(`/dashboard/work-order-flow`);
    }
}
