import PaletteModule from '@/bpmn-modules/diagram-js/lib/features/palette';
import CreateModule from '@/bpmn-modules/diagram-js/lib/features/create';
import SpaceToolModule from '@/bpmn-modules/diagram-js/lib/features/space-tool';
import LassoToolModule from '@/bpmn-modules/diagram-js/lib/features/lasso-tool';
import HandToolModule from '@/bpmn-modules/diagram-js/lib/features/hand-tool';
import GlobalConnectModule from '@/bpmn-modules/diagram-js/lib/features/global-connect';
import translate from '@/bpmn-modules/diagram-js/lib/i18n/translate';

import PaletteProvider from './PaletteProvider';

export default {
    __depends__: [
        PaletteModule,
        CreateModule,
        SpaceToolModule,
        LassoToolModule,
        HandToolModule,
        GlobalConnectModule,
        translate
    ],
    __init__: ['paletteProvider'],
    paletteProvider: ['type', PaletteProvider]
};
