'use strict';

var inherits = require('inherits');

var PropertiesActivator = require('../../PropertiesActivator');

var asyncCapableHelper = require('../../helper/AsyncCapableHelper'),
    ImplementationTypeHelper = require('../../helper/ImplementationTypeHelper');

var is = require('@/bpmn-modules/bpmn-js/lib/util/ModelUtil').is;

// bpmn properties
var processProps = require('../bpmn/parts/ProcessProps'),
    eventProps = require('../bpmn/parts/EventProps'),
    linkProps = require('../bpmn/parts/LinkProps'),
    documentationProps = require('../bpmn/parts/DocumentationProps'),
    idProps = require('../bpmn/parts/IdProps'),
    nameProps = require('../bpmn/parts/NameProps'),
    executableProps = require('../bpmn/parts/ExecutableProps');

// camunda properties
var serviceTaskDelegateProps = require('./parts/ServiceTaskDelegateProps'),
    userTaskProps = require('./parts/UserTaskProps'),
    asynchronousContinuationProps = require('./parts/AsynchronousContinuationProps'),
    callActivityProps = require('./parts/CallActivityProps'),
    multiInstanceProps = require('./parts/MultiInstanceLoopProps'),
    conditionalProps = require('./parts/ConditionalProps'),
    scriptProps = require('./parts/ScriptTaskProps'),
    errorProps = require('./parts/ErrorEventProps'),
    formProps = require('./parts/FormProps'),
    startEventInitiator = require('./parts/StartEventInitiator'),
    variableMapping = require('./parts/VariableMappingProps'),
    versionTag = require('./parts/VersionTagProps'),
    processVariablesProps = require('./parts/ProcessVariablesProps');

var listenerProps = require('./parts/ListenerProps'),
    listenerDetails = require('./parts/ListenerDetailProps'),
    listenerFields = require('./parts/ListenerFieldInjectionProps');

var elementTemplateDescriptionProps = require('./element-templates/parts/DescriptionProps'),
    elementTemplateChooserProps = require('./element-templates/parts/ChooserProps'),
    elementTemplateCustomProps = require('./element-templates/parts/CustomProps'),
    elementTemplateInputParametersProps = require('./element-templates/parts/InputParametersProps'),
    elementTemplateOutputParametersProps = require('./element-templates/parts/OutputParametersProps'),
    getTemplateId = require('./element-templates/Helper').getTemplateId;

// Input/Output
var inputParameters = require('./parts/InputParametersProps'),
    outputParameters = require('./parts/OutputParametersProps');

// Connector
var connectorDetails = require('./parts/ConnectorDetailProps'),
    connectorInputParameters = require('./parts/ConnectorInputParametersProps'),
    connectorOutputParameters = require('./parts/ConnectorOutputParametersProps');

// properties
var properties = require('./parts/PropertiesProps');

// job configuration
var jobConfiguration = require('./parts/JobConfigurationProps');

// history time to live
var historyTimeToLive = require('./parts/HistoryTimeToLiveProps');

// candidate starter groups/users
var candidateStarter = require('./parts/CandidateStarterProps');

// tasklist
var tasklist = require('./parts/TasklistProps');

// external task configuration
var externalTaskConfiguration = require('./parts/ExternalTaskConfigurationProps');

// field injection
var fieldInjections = require('./parts/FieldInjectionProps');

var getBusinessObject = require('@/bpmn-modules/bpmn-js/lib/util/ModelUtil').getBusinessObject,
    eventDefinitionHelper = require('../../helper/EventDefinitionHelper'),
    implementationTypeHelper = require('../../helper/ImplementationTypeHelper');

// helpers ////////////////////////////////////////

var isExternalTaskPriorityEnabled = function (element) {
    var businessObject = getBusinessObject(element);

    // show only if element is a process, a participant ...
    if (is(element, 'bpmn:Process') || is(element, 'bpmn:Participant') && businessObject.get('processRef')) {
        return true;
    }

    var externalBo = ImplementationTypeHelper.getServiceTaskLikeBusinessObject(element),
        isExternalTask = ImplementationTypeHelper.getImplementationType(externalBo) === 'external';

    // ... or an external task with selected external implementation type
    return !!ImplementationTypeHelper.isExternalCapable(externalBo) && isExternalTask;
};

var isJobConfigEnabled = function (element) {
    var businessObject = getBusinessObject(element);

    if (is(element, 'bpmn:Process') || is(element, 'bpmn:Participant') && businessObject.get('processRef')) {
        return true;
    }

    // async behavior
    var bo = getBusinessObject(element);
    if (asyncCapableHelper.isAsyncBefore(bo) || asyncCapableHelper.isAsyncAfter(bo)) {
        return true;
    }

    // timer definition
    if (is(element, 'bpmn:Event')) {
        return !!eventDefinitionHelper.getTimerEventDefinition(element);
    }

    return false;
};

var getListenerLabel = function (param, translate) {

    if (is(param, 'camunda:ExecutionListener')) {
        return translate('Execution Listener');
    }

    if (is(param, 'camunda:TaskListener')) {
        return translate('Task Listener');
    }

    return '';
};

var PROCESS_KEY_HINT = 'This maps to the process definition key.';
var TASK_KEY_HINT = 'This maps to the task definition key.';

function getIdOptions (element) {
    if (is(element, 'bpmn:Process')) {
        return { description: PROCESS_KEY_HINT };
    }

    if (is(element, 'bpmn:UserTask')) {
        return { description: TASK_KEY_HINT };
    }
}

function getProcessOptions (element) {
    if (is(element, 'bpmn:Participant')) {
        return { processIdDescription: PROCESS_KEY_HINT };
    }
}

function createElementTemplateGroups (element, bpmnFactory, canvas, elementTemplates, modeling, replace, selection, translate) {
    var templateId = getTemplateId(element);

    if (!templateId) {
        return [];
    }

    var descriptionGroup = elementTemplateDescriptionProps(element, elementTemplates, modeling, replace, selection, translate);

    var idOptions = getIdOptions(element) || {};

    idOptions.id = 'element-template-element-id';

    var nameOptions = { id: 'element-template-element-name' };

    idProps(descriptionGroup, element, translate, idOptions);
    nameProps(descriptionGroup, element, bpmnFactory, canvas, translate, nameOptions);
    processProps(descriptionGroup, element, translate, getProcessOptions(element));

    var elementTemplateInputParametersGroup = {
        id: 'template-inputs',
        label: translate('Input Parameters'),
        entries: []
    };
    elementTemplateInputParametersProps(elementTemplateInputParametersGroup, element, elementTemplates, bpmnFactory, translate);

    var elementTemplateOutputParametersGroup = {
        id: 'template-outputs',
        label: translate('Output Parameters'),
        entries: []
    };
    elementTemplateOutputParametersProps(elementTemplateOutputParametersGroup, element, elementTemplates, bpmnFactory, translate);

    var customFieldsGroups = elementTemplateCustomProps(element, elementTemplates, bpmnFactory, translate);

    return [
        descriptionGroup,
        elementTemplateInputParametersGroup,
        elementTemplateOutputParametersGroup
    ].concat(customFieldsGroups);
}

function createGeneralTabGroups (
    element, canvas, bpmnFactory,
    elementRegistry, elementTemplates, translate) {

    // refer to target element for external labels
    element = element.labelTarget || element;

    var generalGroup = {
        id: 'general',
        label: translate('General'),
        entries: []
    };

    idProps(generalGroup, element, translate, getIdOptions(element));
    nameProps(generalGroup, element, bpmnFactory, canvas, translate);
    processProps(generalGroup, element, translate, getProcessOptions(element));
    versionTag(generalGroup, element, translate);
    executableProps(generalGroup, element, translate);
    elementTemplateChooserProps(generalGroup, element, elementTemplates, translate);

    var detailsGroup = {
        id: 'details',
        label: translate('Details'),
        entries: []
    };
    serviceTaskDelegateProps(detailsGroup, element, bpmnFactory, translate);
    userTaskProps(detailsGroup, element, translate);
    scriptProps(detailsGroup, element, bpmnFactory, translate);
    linkProps(detailsGroup, element, translate);
    callActivityProps(detailsGroup, element, bpmnFactory, translate);
    eventProps(detailsGroup, element, bpmnFactory, elementRegistry, translate);
    errorProps(detailsGroup, element, bpmnFactory, translate);
    conditionalProps(detailsGroup, element, bpmnFactory, translate);
    startEventInitiator(detailsGroup, element, translate); // this must be the last element of the details group!

    var multiInstanceGroup = {
        id: 'multiInstance',
        label: translate('Multi Instance'),
        entries: []
    };
    multiInstanceProps(multiInstanceGroup, element, bpmnFactory, translate);

    var asyncGroup = {
        id: 'async',
        label: translate('Asynchronous Continuations'),
        entries: []
    };
    asynchronousContinuationProps(asyncGroup, element, bpmnFactory, translate);

    var jobConfigurationGroup = {
        id: 'jobConfiguration',
        label: translate('Job Configuration'),
        entries: [],
        enabled: isJobConfigEnabled
    };
    jobConfiguration(jobConfigurationGroup, element, bpmnFactory, translate);

    var externalTaskGroup = {
        id: 'externalTaskConfiguration',
        label: translate('External Task Configuration'),
        entries: [],
        enabled: isExternalTaskPriorityEnabled
    };
    externalTaskConfiguration(externalTaskGroup, element, bpmnFactory, translate);


    var candidateStarterGroup = {
        id: 'candidateStarterConfiguration',
        label: translate('Candidate Starter Configuration'),
        entries: []
    };
    candidateStarter(candidateStarterGroup, element, bpmnFactory, translate);

    var historyTimeToLiveGroup = {
        id: 'historyConfiguration',
        label: translate('History Configuration'),
        entries: []
    };
    historyTimeToLive(historyTimeToLiveGroup, element, bpmnFactory, translate);

    var tasklistGroup = {
        id: 'tasklist',
        label: translate('Tasklist Configuration'),
        entries: []
    };
    tasklist(tasklistGroup, element, bpmnFactory, translate);

    var documentationGroup = {
        id: 'documentation',
        label: translate('Documentation'),
        entries: []
    };
    documentationProps(documentationGroup, element, bpmnFactory, translate);

    var groups = [];
    groups.push(generalGroup);
    groups.push(detailsGroup);
    // groups.push(externalTaskGroup);
    groups.push(multiInstanceGroup);
    // groups.push(asyncGroup);
    // groups.push(jobConfigurationGroup);
    // groups.push(candidateStarterGroup);
    // groups.push(historyTimeToLiveGroup);
    // groups.push(tasklistGroup);
    // groups.push(documentationGroup);

    return groups;
}

function createVariablesTabGroups (element, bpmnFactory, elementRegistry, translate) {
    var variablesGroup = {
        id: 'variables',
        label: translate('Variables'),
        entries: []
    };
    variableMapping(variablesGroup, element, bpmnFactory, translate);

    return [
        variablesGroup
    ];
}

function createProcessVariablesTabGroups (element, translate) {
    var processVariablesGroup = {
        id: 'process-variables',
        label: translate('Variables'),
        entries: []
    };

    processVariablesProps(processVariablesGroup, element, translate);

    return [
        processVariablesGroup
    ];
}

function createFormsTabGroups (element, bpmnFactory, elementRegistry, translate) {
    var formGroup = {
        id: 'forms',
        label: translate('Forms'),
        entries: []
    };
    formProps(formGroup, element, bpmnFactory, translate);

    return [
        formGroup
    ];
}

function createListenersTabGroups (element, bpmnFactory, elementRegistry, translate) {

    var listenersGroup = {
        id: 'listeners',
        label: translate('Listeners'),
        entries: []
    };

    var options = listenerProps(listenersGroup, element, bpmnFactory, translate);

    var listenerDetailsGroup = {
        id: 'listener-details',
        entries: [],
        enabled: function (element, node) {
            return options.getSelectedListener(element, node);
        },
        label: function (element, node) {
            var param = options.getSelectedListener(element, node);
            return getListenerLabel(param, translate);
        }
    };

    listenerDetails(listenerDetailsGroup, element, bpmnFactory, options, translate);

    var listenerFieldsGroup = {
        id: 'listener-fields',
        label: translate('Field Injection'),
        entries: [],
        enabled: function (element, node) {
            return options.getSelectedListener(element, node);
        }
    };

    listenerFields(listenerFieldsGroup, element, bpmnFactory, options, translate);

    return [
        listenersGroup,
        listenerDetailsGroup,
        // listenerFieldsGroup
    ];
}

function createInputOutputTabGroups (element, bpmnFactory, elementTemplates, translate) {

    var inputParametersGroup = {
        id: 'input-parameters',
        label: translate('Input Parameters'),
        entries: []
    };

    inputParameters(inputParametersGroup, element, bpmnFactory, elementTemplates, translate);

    var outputParametersGroup = {
        id: 'output-parameters',
        label: translate('Output Parameters'),
        entries: []
    };

    outputParameters(outputParametersGroup, element, bpmnFactory, elementTemplates, translate);

    return [
        inputParametersGroup,
        outputParametersGroup
    ];
}

function createConnectorTabGroups (element, bpmnFactory, elementRegistry, translate) {
    var connectorDetailsGroup = {
        id: 'connector-details',
        label: translate('Details'),
        entries: []
    };

    connectorDetails(connectorDetailsGroup, element, bpmnFactory, translate);

    var connectorInputParametersGroup = {
        id: 'connector-input-parameters',
        label: translate('Input Parameters'),
        entries: []
    };

    connectorInputParameters(connectorInputParametersGroup, element, bpmnFactory, translate);

    var connectorOutputParametersGroup = {
        id: 'connector-output-parameters',
        label: translate('Output Parameters'),
        entries: []
    };

    connectorOutputParameters(connectorOutputParametersGroup, element, bpmnFactory, translate);

    return [
        connectorDetailsGroup,
        connectorInputParametersGroup,
        connectorOutputParametersGroup
    ];
}

function createFieldInjectionsTabGroups (element, bpmnFactory, elementRegistry, translate) {

    var fieldGroup = {
        id: 'field-injections-properties',
        label: translate('Field Injections'),
        entries: []
    };

    fieldInjections(fieldGroup, element, bpmnFactory, translate);

    return [
        fieldGroup
    ];
}

function createExtensionElementsGroups (element, bpmnFactory, elementRegistry, translate) {

    var propertiesGroup = {
        id: 'extensionElements-properties',
        label: translate('Properties'),
        entries: []
    };
    properties(propertiesGroup, element, bpmnFactory, translate);

    return [
        propertiesGroup
    ];
}

// Camunda Properties Provider /////////////////////////////////////


/**
 * A properties provider for Camunda related properties.
 *
 * @param {BpmnFactory} bpmnFactory
 * @param {Canvas} canvas
 * @param {ElementRegistry} elementRegistry
 * @param {ElementTemplates} elementTemplates
 * @param {EventBus} eventBus
 * @param {Modeling} modeling
 * @param {Replace} replace
 * @param {Selection} selection
 * @param {Translate} translate
 */
function CamundaPropertiesProvider (
    bpmnFactory,
    canvas,
    elementRegistry,
    elementTemplates,
    eventBus,
    modeling,
    replace,
    selection,
    translate
) {
    PropertiesActivator.call(this, eventBus);

    this.getTabs = function (element) {

        var generalTab = {
            id: 'general',
            label: translate('General'),
            groups: createGeneralTabGroups(
                element, canvas, bpmnFactory,
                elementRegistry, elementTemplates, translate)
        };

        var elementTemplateTab = {
            id: 'element-template',
            label: translate('Template'),
            groups: createElementTemplateGroups(
                element, bpmnFactory, canvas, elementTemplates, modeling, replace, selection, translate)
        };

        var variablesTab = {
            id: 'variables',
            label: translate('Variables'),
            groups: createVariablesTabGroups(element, bpmnFactory, elementRegistry, translate)
        };

        var processVariablesTab = {
            id: 'process-variables',
            label: translate('Variables'),
            groups: createProcessVariablesTabGroups(element, translate)
        };

        var formsTab = {
            id: 'forms',
            label: translate('Forms'),
            groups: createFormsTabGroups(element, bpmnFactory, elementRegistry, translate)
        };

        var listenersTab = {
            id: 'listeners',
            label: translate('Listeners'),
            groups: createListenersTabGroups(element, bpmnFactory, elementRegistry, translate),
            enabled: function (element) {
                return !eventDefinitionHelper.getLinkEventDefinition(element)
                    || (!is(element, 'bpmn:IntermediateThrowEvent')
                        && eventDefinitionHelper.getLinkEventDefinition(element));
            }
        };

        var inputOutputTab = {
            id: 'input-output',
            label: translate('Input/Output'),
            groups: createInputOutputTabGroups(element, bpmnFactory, elementTemplates, translate)
        };

        var connectorTab = {
            id: 'connector',
            label: translate('Connector'),
            groups: createConnectorTabGroups(element, bpmnFactory, elementRegistry, translate),
            enabled: function (element) {
                var bo = implementationTypeHelper.getServiceTaskLikeBusinessObject(element);
                return bo && implementationTypeHelper.getImplementationType(bo) === 'connector';
            }
        };

        var fieldInjectionsTab = {
            id: 'field-injections',
            label: translate('Field Injections'),
            groups: createFieldInjectionsTabGroups(element, bpmnFactory, elementRegistry, translate)
        };

        var extensionsTab = {
            id: 'extensionElements',
            label: translate('Extensions'),
            groups: createExtensionElementsGroups(element, bpmnFactory, elementRegistry, translate)
        };

        return [
            generalTab,
            elementTemplateTab,
            // variablesTab,
            // processVariablesTab,
            // connectorTab,
            // formsTab,
            listenersTab,
            // inputOutputTab,
            // fieldInjectionsTab,
            // extensionsTab
        ];
    };

}

CamundaPropertiesProvider.$inject = [
    'bpmnFactory',
    'canvas',
    'elementRegistry',
    'elementTemplates',
    'eventBus',
    'modeling',
    'replace',
    'selection',
    'translate'
];

inherits(CamundaPropertiesProvider, PropertiesActivator);

module.exports = CamundaPropertiesProvider;
