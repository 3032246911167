'use strict';

var getBusinessObject = require('@/bpmn-modules/bpmn-js/lib/util/ModelUtil').getBusinessObject,
    is = require('@/bpmn-modules/bpmn-js/lib/util/ModelUtil').is,
    entryFactory = require('../../../factory/EntryFactory'),
    cmdHelper = require('../../../helper/CmdHelper'),
    scriptImplementation = require('./implementation/Script');


module.exports = function (group, element, bpmnFactory, translate) {
    var bo;

    if (is(element, 'bpmn:ScriptTask')) {
        bo = getBusinessObject(element);
    }

    if (!bo) {
        return;
    }

    var script = scriptImplementation('scriptFormat', 'script', false, translate);
    group.entries.push({
        id: 'script-implementation',
        label: translate('Script'),
        html: script.template,

        get: function (element) {
            return script.get(element, bo);
        },

        set: function (element, values, containerElement) {
            var properties = script.set(element, values, containerElement);

            return cmdHelper.updateProperties(element, properties);
        },

        validate: function (element, values) {
            return script.validate(element, values);
        },

        script: script,

        cssClasses: ['bpp-textfield']

    });

    group.entries.push(entryFactory.textField(translate, {
        id: 'scriptResultVariable',
        label: translate('Result Variable'),
        modelProperty: 'scriptResultVariable',

        get: function (element, propertyName) {
            var boResultVariable = bo.get('camunda:resultVariable');

            return { scriptResultVariable: boResultVariable };
        },

        set: function (element, values, containerElement) {
            return cmdHelper.updateProperties(element, {
                'camunda:resultVariable': values.scriptResultVariable.length
                    ? values.scriptResultVariable
                    : undefined
            });
        }

    }));

};
