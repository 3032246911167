import DirectEditingModule from '@/bpmn-modules/diagram-js-direct-editing';
import ContextPadModule from '@/bpmn-modules/diagram-js/lib/features/context-pad';
import SelectionModule from '@/bpmn-modules/diagram-js/lib/features/selection';
import ConnectModule from '@/bpmn-modules/diagram-js/lib/features/connect';
import CreateModule from '@/bpmn-modules/diagram-js/lib/features/create';
import PopupMenuModule from '../popup-menu';

import ContextPadProvider from './ContextPadProvider';

export default {
    __depends__: [
        DirectEditingModule,
        ContextPadModule,
        SelectionModule,
        ConnectModule,
        CreateModule,
        PopupMenuModule
    ],
    __init__: ['contextPadProvider'],
    contextPadProvider: ['type', ContextPadProvider]
};